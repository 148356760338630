.svg-pi-wrapper {
  position: relative;
}

.svg-pi {
  transform: rotate(-90deg);
}

.svg-pi-indicator--spinner {
  transform-origin: center;
  animation: .75s linear infinite spinner;
}

.svg-pi-label {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svg-pi-label__loading {
  opacity: .5;
  font-size: 1rem;
}

.svg-pi-label__progress {
  margin-top: -.5rem;
  font-size: 1.5em;
  font-weight: bold;
}

.svg-pi-label__loading, .svg-pi-label__progress {
  display: block;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.react-grid-layout {
  transition: height .2s;
  position: relative;
}

.react-grid-item {
  transition: left .2s, top .2s, width .2s, height .2s;
}

.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform, width, height;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
  transition: none;
}

.react-grid-item.react-draggable-dragging {
  z-index: 3;
  will-change: transform;
  transition: none;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  opacity: .2;
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  background: red;
  transition-duration: .1s;
}

.react-grid-item.react-grid-placeholder.placeholder-resizing {
  transition: none;
}

.react-grid-item > .react-resizable-handle {
  width: 20px;
  height: 20px;
  position: absolute;
}

.react-grid-item > .react-resizable-handle:after {
  content: "";
  border-bottom: 2px solid #0006;
  border-right: 2px solid #0006;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
  transform: rotate(270deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w, .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  cursor: ew-resize;
  margin-top: -10px;
  top: 50%;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n, .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  cursor: ns-resize;
  margin-left: -10px;
  left: 50%;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  width: 20px;
  height: 20px;
  padding: 0 3px 3px 0;
  position: absolute;
}

.react-resizable-handle-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.react-resizable-handle-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
  transform: rotate(270deg);
}

.react-resizable-handle-w, .react-resizable-handle-e {
  cursor: ew-resize;
  margin-top: -10px;
  top: 50%;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n, .react-resizable-handle-s {
  cursor: ns-resize;
  margin-left: -10px;
  left: 50%;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: .9;
}

.core-styles-module_tooltip__3vRRp {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  will-change: opacity, visibility;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.core-styles-module_fixed__pcSol {
  position: fixed;
}

.core-styles-module_arrow__cvMwQ {
  background: inherit;
  position: absolute;
}

.core-styles-module_noArrow__xock6 {
  display: none;
}

.core-styles-module_clickable__ZuTTB {
  pointer-events: auto;
}

.core-styles-module_show__Nt9eE {
  visibility: visible;
  opacity: var(--rt-opacity);
}

.styles-module_tooltip__mnnfp {
  border-radius: 3px;
  width: max-content;
  padding: 8px 16px;
  font-size: 90%;
}

.styles-module_arrow__K0L3T {
  width: 8px;
  height: 8px;
}

[class*="react-tooltip__place-top"] > .styles-module_arrow__K0L3T {
  transform: rotate(45deg);
}

[class*="react-tooltip__place-right"] > .styles-module_arrow__K0L3T {
  transform: rotate(135deg);
}

[class*="react-tooltip__place-bottom"] > .styles-module_arrow__K0L3T {
  transform: rotate(225deg);
}

[class*="react-tooltip__place-left"] > .styles-module_arrow__K0L3T {
  transform: rotate(315deg);
}

.styles-module_dark__xNqje {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.styles-module_light__Z6W-X {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.styles-module_success__A2AKt {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}

.styles-module_warning__SCK0X {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}

.styles-module_error__JvumD {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}

.styles-module_info__BWdHW {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}
/*# sourceMappingURL=index.177d2047.css.map */
